.tradeParentRow{
    height: calc(70% - 54px);
}

.tradeChildRow{
    height: 24%;
}

.paneldecorationTab{
    overflow-y: scroll ;
    overflow-x: hidden;
}

.chartHeading{
    margin-left: 11px;
    margin-top: 6px;
}
.listItem{
    border: 0px !important;
    background: transparent !important;
}
.watchListCol{
    padding: 15px;
}

.snapshotCol{
    padding: 15px 0px;
}