
.parentTable tr th:nth-child(12){
  text-align: center !important;
}
 

.parentTable colgroup col:nth-child(14){
  min-width:90px !important;
}
 
  .nestedTable tr td:nth-child(-n+5),
  .nestedTable tr th:nth-child(-n+5) {
    border: 0px !important;
  }
  .nestedTd{
    padding:  0px !important;
  }

  .expandBtn.focus, .expandBtn:focus{
    box-shadow: none !important;
  }

  .orderSummary{
    height: calc(50% - 8px);
  }