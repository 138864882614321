#formGeneratorWrapper {
  height: 100%;
  width: 100%;
}

#formInputsWrapper {
  max-height: 66vh;
  /* min-height: 66vh; */
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  padding-right: 10px;
}
.form-generator-card,
.form-generator-card .card-body {
  background-color: #31475a !important;
}

.bootstrap-datepicker-wrapper {
  .react-datepicker-wrapper {
    padding: 0!important;
    color: black;
  }
  .react-datepicker__input-container {
    input {
      padding: 0.25rem 0.5rem !important;
      font-size: 0.875rem !important;
      border: 1 px solid #ced4da!important;
      color: #495057!important;
      border-radius: 0.2rem !important;
    }
  }
}

.bootstrap-datepicker-wrapper .react-datepicker-wrapper,
.bootstrap-datepicker-wrapper .react-datepicker__input-container,
.bootstrap-datepicker-wrapper .react-datepicker__input-container input {
  display: block !important;
  width: 100% !important;
  padding: 0;
  margin: 0;
}

#formInputsWrapper::-webkit-scrollbar {
  width: 10px;
  margin-left: 10px;
  border-bottom: 1px solid white;
}

/* Track */
#formInputsWrapper::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

/* Handle */
#formInputsWrapper::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 10px;
}

/* Handle on hover */
#formInputsWrapper::-webkit-scrollbar-thumb:hover {
  background: wheat;
}


.invalid-field{
  width: "100%";
  margin-top: "0.25rem";
  font-size: "80%";
  color : #dc3545;
}