.login-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}
.login-card {
  width: 21rem;
  background-color: rgb(0 0 0 / 50%) !important;
  padding: 0.43rem;
}
.login-card .card-body{
  background-color: transparent!important;
}
