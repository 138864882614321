.modal-header {
    background-color: #485563;
    border: none;
    border-radius: 0;
    color: white;
    font-size: 19px;
    border-bottom: unset !important;
  }
  .modal-header button {
    color: white;
  }
  .modal-dialog {
    width: 100% !important;
    max-width: 100% !important;
    margin: 0 auto !important;
  }
  .modal-title {
    font-size: 19px;
    margin: 0;
  }