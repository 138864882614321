
.cardBody{
  background: rgb(72 85 99);
}
.primaryLabel{
    background: #DF6A34;
    color: #fff;
    line-height: 1.5;
    cursor: pointer;
  }
  
  .secondaryLabel{
    background: #D8534F;
    color: #fff;
    line-height: 1.5;
    cursor: pointer;
  }

  .chartRef{
    position:absolute;
    width:100%;
    height:76%;
    padding:0px 15px 0px 15px;
  }


  .modalChartRef{
    width:100%;
    height:550px;
  }
.badgeContainer{
  padding: 15px 15px 0px 15px;
}

.labelContainer{
  padding: 0px 15px 15px 15px;
  color: #ebebeb;
} 

.label-line-actual{
  width: 20px;
    height: 4px;
  background: rgba(91, 192, 222, 1);
  margin-right: 4px;
}

.label-line-sentiment{
  width: 20px;
    height: 4px;
  background:rgba(240, 172, 78, 1);
  margin-right: 4px;
}


@media (min-width: 768px) {  
  .chart-container{
    min-height: 250px !important;
  }
}
