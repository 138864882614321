.profile-photo{
    max-width: 35%!important;
}
.profile-card{
    background-color: rgb(49 71 90);
}
.profile-form-section{
    height: 50vh;
}
.profile-form-photo-card{
    width: 27rem;
    margin-left: 0.2rem;
}
.profile-form-input-col{
    height: 50px ;
}