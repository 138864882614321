.sidebar {
 
  background-color: #485563;
  min-height: 100vh !important;
  z-index: 99;
  padding: 10px 0 0;
  position: absolute;
  font-size: 15px;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
#sidebar-wrapper {
  min-height: 100vh !important;
  width: 100vw;
  margin-left: -1rem;
  -webkit-transition: margin 0.25s ease-out;
  -moz-transition: margin 0.25s ease-out;
  -o-transition: margin 0.25s ease-out;
  transition: margin 0.25s ease-out;
}
#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#page-content-wrapper {
  min-width: 0;
  width: 100%;
}
.active {
  width: 220px;
  /* transition: all 0.1s ease; */
}

.deactive {
  width: 50px;
  transition: all 0.5s ease;
}
.card {
  background-color: transparent;
  border: unset;
  border-radius: unset;
}
.card-header {
  padding: 0.5rem 1rem;
  background-color: transparent;
  border-bottom: unset;
}

/* .card .nav-item:hover {
  background-color: #020203;
} */
.submenu :not(:first-child) {
  background-color: rgba(0, 0, 0, 0.5);
  margin-left: 0px;
}
.toggle {
  margin-top: 5px;
  float: right;
}
.arrow-closed {
  display: inline;
  justify-content: space-between;
}


.dropdown-toggle::after {
  float: right;
  margin: 5px;
}

.margin-bottom {
  margin-bottom: 100px;
}
		
.nav-header{
    font-size: 12px;
    background: #485563;
    color: #87929B;
    font-weight: 900 !important;
}

.dashboard-tab-content{
  width: 100%;
  height: 100%;
 border: 1px solid #888;
}

.dropdown-tab-button{
    width: 100% !important;
    border:  none !important;
    height: 100% !important;
    padding: 0px !important;
}
.dropdown-tab-button button{
  width: 100% !important;
  border:  none !important;
  font-size: 15px !important;
  line-height: 1.1 !important;
  height: 100% !important;
}
.dropdown-tab-button button:focus{
  box-shadow: none;
  outline: none;
}
.dropdown-menu{
  text-align: center;
}
.dropdown-tab-button .dropdown-menu{
  width: 100% !important;
  min-width: 12rem !important;
}

.dropdown-tab-button .dropdown-menu a {
  font-size: 15px !important;
  padding: .50rem 1.5rem !important;
}
.other-tab{
  padding: 0 !important;
  display: block !important;
}
 
@media (max-width: 575.98px) { 
  .table-panel-header{
    display: block !important;
    margin-top: 15px;
  }
  .react-datepicker-wrapper{
    padding: 0px !important;
    margin: 0px !important;
  }

  .equity button {
    padding: 0px;
  }
}
.market-summary{
  padding: 15px;
}

.bond-table table td , .bond-table table th{
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}