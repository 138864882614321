

.btn-add,
.btn-add:hover,
.btn-add:focus {
  background-color: #3799a7;
  border-color: #3799a7;
  color: #fff;
}

.btn-search,
.btn-search:hover,
.btn-search:focus {
  background-color: #59c4c7;
  border-color: #59c4c7;
  color: #fff;
}

.btn-refresh,
.btn-refresh:hover,
.btn-refresh:focus {
  background-color: #0088cc;
  border-color: #0088cc;
  color: #fff;
}

.btn-block {
  border: 0px;
  padding: 5px 0px;
}