.menu-right {
    font-size: 10px;
  }
  .menu-right a {
    padding-bottom: 9.5px;
    position: relative;
    display: block;
    padding: 10px 15px;
    line-height: 21px;
    color: #fff !important;
  }
  .fa-bars {
    color: #ddd;
    font-size: 12px;
  }
  
  .d-inline-block {
    height: 20px;
    margin-right: 5px;
    vertical-align: middle !important;
  }
  .header-text {
    color: #ddd;
    letter-spacing: 2px;
    font-size: 16px !important;
  }
  .navbar-expand {
    height: 40px;
  }

  
.hide {
    display: none;
  }
  .show {
    display: inline;
  }
  .nav-link {
    color: #e8e8e8;
    padding: 0;
    letter-spacing: 1px;
  }
  a:hover {
    color: #fff !important;
  }
  .nav-item:hover {
    text-decoration: none;
    color: #fff;
    background: rgba(255, 255, 255, 0.2);
  }

  .sidebar-open {
  padding-left: 50px;
  transition: all 0.5s ease;
}
.sidebar-close {
  padding-left: 220px;
  transition: all 0.5s ease;
}
