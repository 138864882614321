.security .view-details label{
    font-size: 12px !important;
}

.security .tab-title{
  font-weight: bold;
  display: inline-block;
}
 
.security .nav-item{
  white-space: nowrap;
}

.security .tab-row{
  overflow-x: scroll;
}
.security .tab-row .bond-menu{
  flex-wrap: nowrap !important;
}

.security .tab-list-group-item .list-group-item:hover{
  background: #4c5761 !important;
}

.security .list-group-item:hover{
  background: #4c5761 !important;
}

.security .list-title{
  color: #fff;
  font-size: 12px;
  margin-bottom: 5px;
  font-weight: 700;
}

.security .list-label{
  color: #dadada!important;
    font-size: 12px;
    font-weight: 700;
}

.security .bond-menu  {
  overflow-x: auto;
  overflow-y: hidden;
}

.security  .bond-menu .row {
  flex-wrap: nowrap;
}

.security  .chart-container{
  min-height: 300px !important;
}

.security  .chart-container .chartBox{
  min-height: 300px !important;
}

.security-list table tbody td{
  text-align: left ;
  width: 100%;
}
.security-list table th{
  display: none !important;
}

.expanded-table table th{
  display: inline-block !important;
}
.expanded-table table td{
  text-align: center !important ;
  width: 100%;
}